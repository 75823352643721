<template>
  <div v-frag>
    <v-container fluid>
      <v-alert
        v-model='alertOptions.show'
        :color='alertOptions.color'
        class='rounded-xl'
        dark
        dense
        dismissible
        icon='mdi-check-circle-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>{{ alertOptions.title }}</h3>
        <p>{{ alertOptions.body }}</p>
      </v-alert>
      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title>
              <v-col cols='12' lg='7' md='7' sm='12' xl='7' xs='12'>
                Charities
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols='12' lg='5' md='%' sm='12' xl='5' xs='12'>
                <v-text-field
                  v-model='charitiesSearch'
                  class='rounded-lg'
                  dense
                  filled
                  hide-details
                  placeholder='Search'
                  prepend-inner-icon='mdi-magnify'
                  rounded
                ></v-text-field>
              </v-col>
            </v-card-title>
            <v-container fluid>
              <v-data-table
                :headers='charitiesHeaders'
                :items='charities'
                :loading='loading'
                :search='charitiesSearch'
                class='elevation-1 data-table-border-radius tableCustom'
                item-key='id'
              >
                <template v-slot:[`item.logo`]='{ item }'>
                  <v-avatar color='grey' size='80'>
                    <v-img :src='item.logo'></v-img>
                  </v-avatar>
                </template>
                <template v-slot:[`item.name`]='{ item }'>
                  <span class='charityName' @click='redirect(item)'>{{ item.name }}</span>
                </template>
                <template v-slot:[`item.actions`]='{ item }'>
                  <v-btn
                    color='primary'
                    depressed
                    small
                    @click='redirect(item)'
                  >
                    View charity
                  </v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import charityService from '../../services/charity/index'

export default {
  data() {
    return {
      loading: true,
      options: {},
      alertOptions: {
        show: false
      },
      charity: {},
      charitiesHeaders: [
        {
          text: 'Logo',
          align: 'start',
          value: 'logo',
          width: '15%',
          divider: true
        },
        {
          text: 'Charity',
          align: 'start',
          value: 'name',
          width: '20%',
          divider: true
        },
        {text: 'Mission', value: 'mission', width: '60%', divider: true},
        {
          text: 'Actions',
          align: 'center',
          value: 'actions',
          width: '10%',
          divider: true
        }
      ],
      charities: [],
      charitiesSearch: ''
    }
  },
  created: function() {
    this.initialize()
    this.getCharity()
  },
  methods: {
    async initialize() {
      try {
        this.charities = await charityService.getActivesCharities()
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    getCharity() {
      this.$store.getters.charities.forEach((element) => {
        if (element.id === this.$route.params.id) {
          this.charity = element
        }
      })
    },
    openAlert(status, message = '') {
      window.scrollTo(0, 0)
      this.alertOptions = {
        show: true,
        color: 'success',
        title: 'Funds Donated',
        body: 'The donation was made successfully'
      }
      if (!status) {
        this.alertOptions.color = 'error'
        this.alertOptions.title = 'Something went wrong!'
        this.alertOptions.body = message
      }
    },
    redirect(item) {
      this.$store.dispatch('charity', item.id)
      this.$router.push('/employee/charity-donations')
    }
  }
}
</script>

<style>
.tableCustom .v-data-table__divider {
  padding: 10px 15px !important;
}
.charityName {
  text-decoration: underline;
}
.charityName:hover {
  color: #000;
  cursor: pointer;
}

.headerTable {
  border: 1px solid #eee;
}
.headerTable th td {
  text-align: center;
}
.border-left {
  border-left: 1px solid #eee;
}
.buttonHeader {
  display: flex;
  flex-direction: column;
  max-height: 5rem;
  margin-top: 1.5rem;
}
</style>
